<template>
  <div>
    <div class="bg-link-water p-3 rounded mb-10">
      <div class="md-layout md-gutter py-2">
        <div class="md-layout-item flex align-center">
          <md-icon class="m-0">record_voice_over</md-icon>
          <span class="text-uppercase font-bold text-lg ml-2"
            >PTE Word List</span
          >
          <span class="text-uppercase ml-2 text-gray-600 font-bold"
            >( Total 125 Vocabularies )</span
          >
        </div>
        <div class="md-layout-item md-size-45 flex justify-end">
          <md-button
            class="bg-green text-white rounded-md w-48 px-2 m-0 text-uppercase"
          >
            <md-icon class="text-white m-0">add</md-icon> Create New
          </md-button>
        </div>

        <div class="md-layout-item md-size-100 mt-6">
          <div
            v-for="item in summeries"
            :key="item.id"
            class="text-lg w-32 px-3 text-center py-3 text-white inline-block mr-3 rounded-md text-uppercase"
            :style="{ backgroundColor: item.color }"
          >
            001
          </div>
        </div>
      </div>
    </div>

    <Table :items="items">
      <template slot="row" slot-scope="{ item }">
        <md-table-cell md-label="#" md-sort-by="id" md-numeric>
          {{ item.id }}
        </md-table-cell>
        <md-table-cell md-label="WORD" md-sort-by="word">
          <div>
            <h3 class="text-victoria text-lg mb-2">Open</h3>
            <p>Please open the windows immediatly</p>
          </div>
        </md-table-cell>
        <md-table-cell md-label="RELATED" md-sort-by="related">
          {{ item.related }}
        </md-table-cell>
        <md-table-cell md-label="PARTS OF SPEECH" md-sort-by="partsOfSpeech">
          {{ item.partsOfSpeech }}
        </md-table-cell>
        <md-table-cell md-label="PHONETICS" md-sort-by="phonetics">
          {{ item.phonetics }}
        </md-table-cell>
        <md-table-cell md-label="PRONUNCIATION" md-sort-by="pronunciation">
          <i
            class="text-lg pointer text-white rounded-full bg-victoria w-8 h-8 center inline-block"
            :class="item.pronunciation"
          ></i>
        </md-table-cell>
        <md-table-cell md-label="MEANING" md-sort-by="meaning">
          {{ item.meaning }}
        </md-table-cell>
        <md-table-cell md-label="SYNONYMS" md-sort-by="synonyms">
          {{ item.synonyms }}
        </md-table-cell>
        <md-table-cell md-label="ANTONYMS" md-sort-by="antonyms">
          {{ item.antonyms }}
        </md-table-cell>
        <md-table-cell>
          <md-icon class="outline-gray-400 text-victoria rounded-sm ">bookmark</md-icon>
        </md-table-cell>
      </template>
    </Table>
  </div>
</template>

<script>
import { Table } from "@/components";
import data from '@/data/material/data'
export default {
  name: "Todo-List",
  components: {
    Table,
  },
  data() {
    return {
      summeries: [
        { id: 1, color: "#524b99" },
        { id: 2, color: "#ff6b6b" },
        { id: 3, color: "#10ac84" },
        { id: 4, color: "#f0932b" },
        { id: 5, color: "#be2edd" },
      ],
      items: data.wordList,
    };
  }
};
</script>
